import React, { useRef, useLayoutEffect } from 'react';
import YouTube from "react-youtube";
import VideoCarousel from './VideoCarousel';
import VideoCarouselMobile from './VideoCarouselMobile';
import './video-box.scss';

const VideoBox = ({ content }) => {
  let players = [];

  const videoContainer = useRef();

  useLayoutEffect(() => {
    videoContainer.current.addEventListener('focus', () => {
      videoContainer.current.scrollIntoView();
    })
  }, [videoContainer]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    touchMove: true,
    accessibility: true,
    autoplay: false
  };

  const videoSettings = {
    height: 'auto',
    width: '95%',
    playerVars: {
      autoplay: 0,
    },
  };

  const videoReady = (event) => {
    players.push(event.target);
  }

  const createVideoPlayers = () => {
    let ui = [];
    content.carouselItems.forEach((item, idx) =>
      ui.push(
        <div key={item} tabIndex={idx}
          className="video-container">

          <YouTube
            opts={videoSettings}
            videoId={item}
            onReady={videoReady}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      )
    )

    return ui;
  }

  return (
    <section className="section videobox" aria-label="TV Ad">
      <div className="section-inner" ref={videoContainer} tabIndex="0">
        <h2>{content.header}</h2>
        {window.innerWidth <= 1024 ? <VideoCarouselMobile
          slides={createVideoPlayers()}
          sliderSettings={sliderSettings}
          content={content}
        />
          : <VideoCarousel
            slides={content.carouselItems}
            sliderSettings={sliderSettings}
            videoSettings={videoSettings}
          />}
      </div>
    </section>
  );
};

export default VideoBox;
