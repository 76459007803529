import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Modal from 'react-modal';
import YouTube from "react-youtube";
import PlayButton from "../../assets/images/play-button.png";

const VideoCarousel = ({ slides, sliderSettings, content }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [videoID, setVideoID] = useState(null);

  useEffect(() => {
    Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, .5";
    Modal.defaultStyles.content.backgroundColor = "#000000";
    Modal.defaultStyles.content.border = "none";
    Modal.setAppElement("#beat-stigma");

  });

  const closeModal = () => {
    setModalOpen(false);
  }

  const handleModal = (videoID) => {
    setModalOpen(true);
    setVideoID(videoID);

  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '95%',
      height: 'auto'
    },
  };

  const videoSettings = {
    height: 'auto',
    width: '95%',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <>
      <Slider {...sliderSettings}>
        {content.carouselItems.map((item, idx) =>

          <div key={item} tabIndex={idx} className="video-container">
            <img className="play-button" src={PlayButton} alt="" onClick={() => handleModal(item)} />
            <img src={`http://img.youtube.com/vi/${item}/maxresdefault.jpg`} alt="" onClick={() => handleModal(item)} />
          </div>
        )}
      </Slider>
      <Modal
        style={customStyles}
        isOpen={modalOpen}
        onRequestClose={closeModal}>
        <div className="video-modal">
          <button onClick={closeModal}>X</button>
          <div className="modal-video-container">
            <YouTube
              opts={videoSettings}
              videoId={videoID}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default VideoCarousel;
