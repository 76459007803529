import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import { LandingContent } from '../../content/quiz-content';
import MoreInfo from '../MoreInfo/MoreInfo';
import VideoBox from '../VideoBox/VideoBox';
import Logo from '../../assets/images/logo-beat-the-stigma-2.png';
import HeadTagManager from '../../utils/HeadTagManager';

const LandingPage = () => {
  const history = useHistory();
  const goToGame = () => {
    history.push('/addiction-mental-health-intro');
  }

  useEffect(() => {
    window.gtag('event', 'conversion', {
      'allow_custom_scripts': true,
      'send_to': 'DC-8663302/adamh-rt/bts-home+standard'
    });

  }, []);
  return (
    <div className="landing-page">
      <HeadTagManager
        title={LandingContent.headTag.title}
        metaTags={LandingContent.headTag.metaTags}
        script={LandingContent.headTag.script} // for video scheme
      />
      <div className="stage">
        <div className="landing-hero" onClick={goToGame} style={{ "cursor": "pointer" }}>
          <span className="sr-only">Play Beat the Stigma</span>
          <h1>
            <img src={Logo} className="landing-logo" alt="Play Beat the Stigma" />
          </h1>
        </div>

        <div className="section-inner">
          <div className="stage-content">
            <p>
              {LandingContent.body}
            </p>
            <Link to="/addiction-mental-health-intro" className="button glow-blue landing-button">
              <span>{LandingContent.buttonText}</span>
            </Link>
            <Link to="/about-addiction-mental-health-stigmas" className="standard-link">{LandingContent.learnMoreLink}</Link>
          </div>
        </div>
      </div>

      {LandingContent.moreInfo && (
        <MoreInfo content={LandingContent.moreInfo} />
      )}

      {LandingContent.videoBox && (
        <VideoBox content={LandingContent.videoBox} />
      )}

      {LandingContent.promo && (
        <div className="promo bg-primary">
          <div className="section-inner">
            <h2>
              {Parser(LandingContent.promo.header)}
            </h2>

            <p>
              {LandingContent.promo.copy}
            </p>

            <Link className="button light-blue" to={LandingContent.promo.button.path}>
              {LandingContent.promo.button.text}
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default LandingPage;
