import React from "react";
import Slider from "react-slick";
import YouTube from "react-youtube";

const VideoCarousel = ({ slides, sliderSettings, videoSettings }) => {

  let players = [];

  const sliderInit = () => {
    setTimeout(() => {
      let iframes = document.querySelectorAll(".slick-slide:not(.slick-current) .video-container iframe");
      for (let i = 0; i < iframes.length; i++) {
        iframes[i].setAttribute('tabIndex', '-1');
      }
      setTimeout(() => {
        let active = document.querySelectorAll(".slick-slide.slick-current .video-container iframe");
        console.log('active.length ', active.length);
        active[0].removeAttribute("tabIndex");
      }, 1000);
    }, 1000);
  }

  const videoReady = (event) => {
    players.push(event.target);
  }
  const onSlideChange = (index) => {
    for (let i = 0; i < players.length; i++) {
      players[i].pauseVideo();
    }
    sliderInit();
  }

  return (
    <Slider
      onInit={sliderInit}
      afterChange={(index) => onSlideChange(index)}
      {...sliderSettings}
    >
      {slides.map((item, idx) =>
        <div key={item} tabIndex={idx}
          className="video-container">
          <YouTube
            opts={videoSettings}
            videoId={item}
            onReady={videoReady}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      )}
    </Slider>
  )
}

export default VideoCarousel;
